import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Hero() {
    return (
        <StaticQuery
            query={graphql`
        query HeroQuery {
          site {
            siteMetadata {
              title,
              description
            }
          }
        }
      `}
            render={data => (
                <section className="py-5 text-center container hero">
                    <div className="row py-lg-5">
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <video src="/smolvid.mp4" poster="/images/poster.png" autoPlay muted playsInline defaultMuted >
                            </video>
                            <div className="hero-text"> 
                            <h1 className="fw-light">{data.site.siteMetadata.title}</h1>
                            <p className="lead badge bg-primary"></p>
                            </div>
                            {<p>
                                {<a href="https://chat.whatsapp.com/DWIEPNVsLzP2ixSmLI0R2f" className="btn btn-primary my-2"><img style={{width: '2em', height: '2em', marginRight: '2em'}} src="/images/whatsapp.png" />Join whatsapp group</a>}
                                {/* <a href="#" className="btn btn-secondary my-2">Secondary action</a> */}
                            </p>}
                        </div>
                    </div>
                </section>
            )}
        />)
}
