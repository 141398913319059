import React from "react"
import Header from "./header"
import Footer from "./footer"
import Hero from "./hero"
import "../styles.scss"

export default function Layout({ children }) {
    return (
        <>
            <Header></Header>
            <main>
                <Hero></Hero>
                {children}
            </main>
            <Footer></Footer>
        </>
    )
}