import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Header() {
    return (

        <StaticQuery
            query={graphql`
    query HeadingQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `}
            render={data => (
                <header>
                    <div className="collapse bg-dark" id="navbarHeader">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-8 col-md-7 py-4">
                                    <h4 className="text-white">About Berlin action boys</h4>
                                    <p className="text-muted">Berlin action boys is <a className="text-white" href="https://roman-guivan.online/">Roman G.</a> and his restless action-sports collective</p>
                                </div>
                                <div className="col-sm-4 offset-md-1 py-4">
                                    <h4 className="text-white">Contact us</h4>
                                    <ul className="list-unstyled">
                                        <li><a target="_blank" href="https://www.facebook.com/roman.guivan" className="text-white">on Facebook</a></li>
                                        <li><a target="_blank" href="https://www.instagram.com/pisjun_pisjun/" className="text-white">on Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar navbar-dark bg-dark shadow-sm">
                        <div className="container">
                            <a href="#" className="navbar-brand d-flex align-items-center">
                                <strong>{data.site.siteMetadata.title}</strong>
                            </a>
                            {/* <ul className="navbar-nav mr-auto">
                                <li className="nav-item"><a className="nav-link" href="#">Home</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Sport</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Technology</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Entertaiment</a></li>
                            </ul> */}
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </div>
                </header>
            )}
        />)
}