import React from "react"
export default function Footer() {
    return (
        <footer className="text-muted py-5">
            <div className="container">
                <p className="float-end mb-1">
                    <a href="#">Back</a>
                </p>
                <p className="mb-1">Berlin action boys + <a className="text-muted" target="_blank" href="https://roman-guivan.online/">Roman G. 2022</a></p>
                <p className="mb-0"><a target="_blank" className="text-muted" href="https://roman-guivan.online/">Roman made this</a> </p>
            </div>
        </footer>
    )
}