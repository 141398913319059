import React from "react"
import moment from "moment"

export default class Card extends React.Component {
    render() {
        return (<div className="card shadow-sm">
            <svg className="bd-placeholder-img card-img-top"
                width="100%" height="285"
                xmlns="http://www.w3.org/2000/svg"
                role="img" aria-label=""
                preserveAspectRatio="xMidYMid slice" focusable="false">
                <image href={this.props.image} preserveAspectRatio="xMinYMin slice" width="100%" height="100%" />
            </svg>
            <div className="card-body">
                <strong>{this.props.title}</strong>
                <p className="card-text">
                    {this.props.text}
                </p>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-secondary">more</button>
                        {/* <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button> */}
                    </div>
                    <small className="text-muted">{moment(this.props.date).fromNow()}</small>
                </div>
            </div>
        </div>)
    }
}
