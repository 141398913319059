import React from "react"
import { graphql } from 'gatsby'
import Card from "../components/card"
import Layout from "../components/layout"

export default function Home({data}) {
  return (
  <Layout>
    <div className="album py-5 bg-light">
      <div className="container">

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          {data.allMarkdownRemark.edges.sort((edge1, edge2)=>{
            return new Date(edge2.node.frontmatter.date) - new Date(edge1.node.frontmatter.date)
          }).map(({ node }, idx) => (
            <div className="col" key={idx}>
              <a href={node.fields.slug}>
                <Card 
                  image={node.fields.featuredImage}
                  title={node.frontmatter.title} 
                  text={node.excerpt}
                  date={node.frontmatter.date}>
              </Card> </a>
            </div>))
          }
        </div>
      </div>
    </div>
  </Layout>)
}

export const query = graphql`
query AllBlogPosts {
  allMarkdownRemark {
    edges {
      node {
         fields {
					slug
          featuredImage
        }
        excerpt
        frontmatter {
					title 
          date
          tags
        }
        
      }
    }
  }
}

`